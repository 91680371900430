<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from '../../components/user/list'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List
  },
  data() {
    return {
      title: "",
      state: ''
    }
  },
  mounted() {
    this.title = this.$t('userManagement')
    this.state = this.$route.params.state
    if (this.state === 'normal') {
      this.title += '&nbsp;<span class="badge bg-primary">'+ this.$t('userStateNormal') + '</span>'
    } else if (this.state === 'block') {
      this.title += '&nbsp;<span class="badge bg-warning">' + this.$t('userStateBlock') + '</span>'
    } else if (this.state === 'unregister') {
      this.title += '&nbsp;<span class="badge bg-danger">' + this.$t('userStateUnregister') + '</span>'
    }
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <List :state="state" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
